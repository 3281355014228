var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blocktitle" },
    [
      _c(
        "div",
        {
          staticClass: "title",
          style: { fontWeight: "" + (_vm.isBold ? "bold" : "500") },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("left")],
        2
      ),
      _vm._t("center"),
      _vm._t("right"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }